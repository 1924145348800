.img-auto-for-children {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  & img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: contain;
  }

  p{
    margin-bottom: 0;
  }
}

.exam-search-box{
  outline: none;
  width: 90%;
  height: 40px;
}


