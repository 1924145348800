.shadow-box {
    background: #fff;
    width: 100%;
    border-radius: 12px;
    box-shadow: -8px 10px #f2fafc;
    border: 1px solid #f2fafc;
    height: 100px;
    padding: 10px 15px 10px 15px;
    margin-bottom: 25px; }
.shadow-box.custom-height {
    height: 120px;
    margin-bottom: 35px; }
.members-circle {
    background-image: linear-gradient(to bottom, #2bc3cc, #6141cc);
    font-size: 12px;
    border: 1px solid #ffffff;
    width: 35px;
    height: 35px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
.title-circle {
    background-color: #fff;
    width: 40px;
    height: 40px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    img {
        object-fit: contain;
        height: 16px;
        width: 100%; } }
.question-card {
    position: relative;
    width: 100%;
    height: 140px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 10px solid #f4fcfd;
    padding: 10px 0px 10px 0px;
    margin-bottom: 35px;
    .answer-label {
        background-color: #f8f8f8;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px; }
    .favorite-label {
        height: 28px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        justify-content: center; }
    .question-body {
        padding: 15px 10px 0px 10px; }
    .question-footer {
        padding-left: 10px;
        height: 30px;
        position: absolute;
        left: 5px;
        bottom: 0; }
    .answers-ratio {
        background-color: #f4fcfd;
        height: 70px;
        padding: 10px 15px 10px 15px;
        border-radius: 5px;
        width: 60%;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: -30px; } }
.react-sweet-progress-symbol {
    padding-right: 5px;
    padding-left: 0px;
    font-size: 11px;
    color: #4b3a85; }
.key-circle {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    font-size: 12px;
    &.success {
        background-color: #2bc3cc; }
    &.failure {
        background-color: #f66271; } }






