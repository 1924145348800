$dark-blue: #4b3a85;
$light-blue: #2bc3cc;
$mid-blue: #6c53bf;
$white: #FFF;
$black: #000000;
$red: #f66271;
$tomato: #ff6575;
$silver: #dbdbdb;
$light-silver: #f2f2f2;
$dark-silver: #a9acb4;
$off-white: #fefefe;
$light-purple: #ddd6f2;

@font-face {
    font-family: GessLight;
    src: url("/assets/fonts/GESSLight.otf"); }
@font-face {
    font-family: GessMedium;
    src: url("/assets/fonts/GESSMedium.otf"); }
@font-face {
    font-family: GessBold;
    src: url("/assets/fonts/GESSBold.otf"); }
@font-face {
    font-family: MontserratMedium;
    src: url("/assets/fonts/MontserratMedium.ttf"); }
@font-face {
    font-family: MontserratRegular;
    src: url("/assets/fonts/MontserratRegular.ttf"); }
@font-face {
    font-family: TheSansArabMedium;
    src: url("/assets/fonts/TheSansArabMedium.ttf"); }
@font-face {
    font-family: TheSansArabBold;
    src: url("/assets/fonts/TheSansArabBold.ttf"); }
html,body {
    font-family: "GessMedium";
    direction: rtl;
    height: 100%; }
/*General styles */
a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
  outline: none; }
.form-control:focus, .form-control:focus, .form-control:hover {
  outline: 0;
  box-shadow: none;
  border-color: $silver; }
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus, button.active.focus, button.active:focus, button.focus, button:active.focus, button:active:focus, button:focus {
  outline: 0;
  box-shadow: none; }
.br-5 {
    border-radius: 5px !important; }
.max-width-300px {
    max-width: 300px; }
.width-100 {
    width: 100px !important; }
.w-33 {
    width: 33% !important; }
.w-40 {
    width: 40% !important; }
.w-20 {
    width: 20% !important; }
.w-35 {
    width: 35%; }
.w-70 {
    width: 70%; }
.w-15 {
    width: 15%; }
.w-90 {
    width: 90%; }
.w-10 {
    width: 10%; }
.h-55 {
    height: 55px; }
.mh-55 {
    min-height: 55px; }
.h-45 {
    height: 45px; }
.h-40 {
    height: 40px; }
.h-80 {
    height: 80px; }
.h-65 {
    height: 65px; }
.active-dote {
    height: 5px;
    width: 5px;
    background-color: $dark-blue;
    border-radius: 50%; }
.shady_question {
    background-color: $light-purple;
    td {
        color: $dark-blue;
        font-weight: bolder; } }
.mt-10 {
    margin-top: 10px; }
.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 5px; }
.react-player {
  position: absolute;
  top: 0;
  left: 0; }
.table td, .table th {
    vertical-align: middle; }
.radius-top-0 {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important; }
.radius-bottom-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important; }
.left-radius-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
.left-radius-5 {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }
.en-text {
    font-family: MontserratMedium; }
.TheSansArabMedium-font {
    font-family: TheSansArabMedium; }
.TheSansArabBold-font {
    font-family: TheSansArabBold; }
.popover {
    font-family: "GessMedium"; }
.dark-text {
    color: $dark-blue !important; }
.line-through-text {
    text-decoration: line-through; }
.br-left-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
.justify-content-evenly {
    justify-content: space-evenly; }
.text-break {
    word-break: break-word; }
.text-not-break {
    word-break: keep-all; }
.light-text {
    color: $light-blue; }
.red-text {
    color: $red; }
.red-bg {
    background-color: $red; }
.mid-text {
    color: $mid-blue !important; }
.dark-silver-text {
    color: $dark-silver; }
.silver-text {
    color: $silver; }
.light-silver-text {
    color: #fbfbfb; }
.dark-bg {
    background: $dark-blue; }
.mid-bg {
    background: $mid-blue; }
.light-bg {
    background-color: $light-blue; }
.silver-bg {
    background-color: $light-silver; }
.light-purple-text {
    color: $light-purple; }
.off-white-bg {
    background-color: $off-white; }
.off-white-text {
    color: $off-white; }
.light-font-text {
    font-family: GessLight; }
.cover-img {
    object-fit: cover;
    object-position: left; }
.contain-img {
    object-fit: contain; }
.smaller {
    font-size: 65% !important; }
.font-16 {
    font-size: 16px; }
.font-13 {
    font-size: 13px; }
.light-border {
    border: 1px solid $light-blue !important; }
.light-border-bottom {
    border-bottom: 1px solid $light-silver !important; }
.green-text {
    color: #109201; }
.green-border {
    border: 1px solid #109201 !important; }
.white-border {
    border: 1px solid $white !important; }
.underlined-tabs {
    li {
        width: 100px;
        text-align: center; }
    li>a.active {
        color: $light-blue; }
    li>a:after {
        display: block;
        content: "";
        border-bottom: 2px solid $light-blue;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        transform-origin: 100% 50%;
        margin: 0 auto;
        margin-top: 5px;
        width: 85%; }
    li>a:hover:after, li>a.active:after {
            transform: scaleX(1);
            ransform-origin: 0 50%; } }

form {
    .input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px; }
    input.form-control::placeholder, textarea.form-control::placeholder {
        color: $silver;
        font-size: 12px; }
    input.form-control {
        color: $dark-blue;
        height: 40px;
        border-radius: 5px;
        border: 1px solid $silver;
        font-size: 13px; }
    select.form-control {
        color: $silver;
        height: 40px;
        border-radius: 5px;
        border: 1px solid $silver;
        font-size: 12px; }
    .input-group-text {
        background-color: $white;
        border: 1px solid $silver;
        height: 40px;
        svg {
            color: $silver;
            font-size: 18px; } }
    input.ltr-input {
        direction: ltr;
        font-family: MontserratMedium; }
    input.ltr-input::-webkit-input-placeholder {
        direction: rtl;
        font-family: GessMedium; }
    small {
        color: $red;
        font-size: 10px;
        padding-top: 5px; }
    .input-error>.input-group-prepend>.input-group-text, .input-error>input.form-control, .input-error>textarea.form-control, .input-error>.intl-tel-input>input, .input-error>select.form-control, .input-error>.rdt>input.form-control {
        border: 1px solid $red;
        svg {
            color: $red; } }
    .input-success>.input-group-prepend>.input-group-text, .input-success>input.form-control, .input-success>textarea.form-control, .input-success>.intl-tel-input>input, .input-success>select.form-control, .input-success>.rdt>input.form-control {
        border: 1px solid $light-blue;
        svg {
            color: $light-blue; } } }
input {
  &[type='number'] {
    -moz-appearance: textfield; }
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none; } }
form.centered {
    margin: 0 auto;
    width: 50%; }
a.btn {
    line-height: 25px; }
.dark-outline-btn {
    border-radius: 50px;
    border: 1px solid $dark-blue;
    color: $dark-blue;
    height: 40px;
    font-size: 13px;
    &:hover, &:focus {
        background-color: $dark-blue;
        color: $white;
        transition: background-color ease-out 0.3s; } }
.dark-btn {
    border-radius: 50px;
    border: 1px solid $dark-blue;
    color: $white;
    height: 40px;
    font-size: 13px;
    background-color: $dark-blue;
    &:hover, &:focus {
        background-color: $white;
        border: 1px solid $dark-blue;
        color: $dark-blue; } }
.light-btn {
    border: 1px solid $light-blue;
    color: $white;
    font-size: 12px;
    background-color: $light-blue;
    &:focus {
        color: $white; }
    &:hover {
        background-color: $white;
        border: 1px solid $light-blue;
        color: $light-blue; } }
.red-btn {
    border: 1px solid $red;
    color: $white;
    font-size: 12px;
    background-color: $red;
    &:hover, &:focus {
        background-color: $white;
        border: 1px solid $red;
        color: $red; } }
.btn-tomato {
    display: block;
    width: 150px;
    background-color: $tomato;
    color: #FFF;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: .8rem;
    line-height: 30px;
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover, &:focus {
        background-color: $dark-blue; } }

.light-outline-btn {
    border-radius: 50px;
    border: 1px solid $light-blue;
    color: $light-blue;
    height: 40px;
    font-size: 13px;
    &:hover, &:focus {
        background-color: $light-blue;
        color: $white;
        transition: background-color ease-out 0.3s; } }
.blue-border-btn {
    border: 1px solid $light-blue;
    color: $light-blue;
    font-size: 11px;
    &:hover, &:focus {
        background-color: $light-blue;
        color: $white;
        transition: background-color ease-out 0.3s; } }
.red-border-btn {
    border: 1px solid $red;
    color: $red;
    font-size: 11px;
    &:hover, &:focus {
        background-color: $red;
        color: $white;
        transition: background-color ease-out 0.3s; } }
.silver-outline-btn {
    border-radius: 50px;
    border: 1px solid #fbfbfb;
    color: #fbfbfb;
    height: 40px;
    font-size: 13px;
    &:hover, &:focus {
        background-color: #fbfbfb;
        color: $dark-blue;
        transition: background-color ease-out 0.3s; } }


.finish-btn {
    display: block;
    width: 150px;
    text-align: center;
    background-color: $dark-blue;
    color: #FFF !important;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: .8rem;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover, &:focus {
       background-color: $light-blue; } }

.headShake {
    &:hover {
        animation-duration: .5s;
        animation-timing-function: cubic-bezier(0,.23,1,.71);
        animation-name: headShake;
        transform-origin:center bottom {} } }


@keyframes headShake {
  0% {
    transform: translateX(0); }

  12.5% {
    transform: translateX(-6px) rotateY(-9deg) skewY(1deg); }

  37.5% {
    transform: translateX(5px) rotateY(4.5deg) skewY(-1deg); }

  62.5% {
    transform: translateX(-3px) rotateY(-2.25deg) skewY(0); }

  87.5% {
    transform: translateX(2px) rotateY(3deg); }

  100% {
    transform: translateX(0); } }

.flash {
    animation-delay: .5s;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0,.23,1,.71);
    animation-name: flash;
    transform-origin:center bottom {}
    animation-iteration-count: 5; }


@keyframes flash {
  0% {
    border: 1px solid $dark-blue;
    color: $white;
    background-color: $dark-blue; }
  100% {
    background-color: $white;
    border: 1px solid $dark-blue;
    color: $dark-blue; } }

.light-btn-flash {
    animation-delay: .5s;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0,.23,1,.71);
    animation-name: light-btn-flash;
    transform-origin:center bottom {}
    animation-iteration-count: infinite; }


@keyframes light-btn-flash {
  0% {
    background-color: $light-blue;
    color: #f66271;
    border: 1px solid $light-blue; }
  100% {
    background-color: $white;
    color: #f66271;
    border: 1px solid $white; } }



.intl-tel-input {
    direction: ltr;
    width: 100%;
    font-family: MontserratMedium;
    .country-list {
        z-index: 12; } }
.phone-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    align-self: center;
    background-color: #9786d2;
    bottom: 20px; }
.react-code-input {
    direction: ltr;
    input {
        font-family: MontserratMedium !important;
        box-shadow: none !important;
        outline: 0 !important;
        text-align: center !important;
        color: $dark-blue !important;
        width: 36px !important;
        height: 36px !important;
        font-size: 18px !important;
        padding-left: 0px !important;
        margin: 4px !important;
        border-radius: 4px !important; } }
.vertical-separator {
    height: 25px;
    background-color: #dbdbdb;
    width: 1px;
    margin-right: 10px;
    margin-left: 10px; }
.vertical-separator.custom-height {
    height: 50px; }

.swal-title, .swal-text {
    color: $dark-blue; }

.half-circle-border {
    width: 150px;
    height: 75px;
    border-top-left-radius: 85px;
    border-top-right-radius: 85px;
    border: 5px solid $light-silver;
    border-bottom: 0; }
.gradient-bg {
    width: 70px;
    height: 70px;
    opacity: 0.7;
    border-radius: 13.5px;
    background-image: linear-gradient(145deg, #2bc3cc, #6141cc); }
.silver-box-bg {
    width: 70px;
    height: 70px;
    border-radius: 13.5px;
    background-color: #fbfbfb; }
.gray-box-border {
    border: 1px solid #434343 !important; }
.unset-height {
    height: unset !important; }
.unset-line-height {
    line-height: unset !important; }
.box-height {
    min-height: 400px; }
.btn-radius {
    border-radius: .2rem !important; }
.box-layout {
   border: 1px solid $silver;
   border-radius: 5px; }
.dashed-border {
   border: 1px dashed $silver; }
.border-dashed {
    border-style: dashed; }
.border-sub-color {
    border-color: $dark-blue; }
.slick-prev:before, .slick-next:before {
   color: $dark-blue; }
.slick-list > .slick-track {
    margin-right: 0; }
.ball-clip-rotate > div {
    margin: 0px; }
.intl-tel-input .selected-flag:focus {
    outline: 0;
    box-shadow: none; }
.disabled-input {
    .input-group-prepend {
        .input-group-text {
            background-color: #e9ecef; } } }
.rdtOpen .rdtPicker {
    font-family: MontserratRegular; }
.rdt {
    direction: ltr;
    input {
        direction: ltr;
        font-family: MontserratRegular; } }
.swal-text {
    text-align: center; }
@media only screen and (max-width: 767px) {
    .w-25, .w-75, .w-40, .w-35 {
        width: 100% !important; }
    .width-100 {
        width: 75px !important; }
    .navbar-light .navbar-toggler {
        border-color: $white; }
    .hero-section {
        margin-top: 50px;
        margin-bottom: 25px;
        h2 {
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 25px; } }
    .features-section {
        padding-top: 60px;
        margin-bottom: 70px; }
    .categories-section {
        padding-top: 0px;
        background-size: 50%; }
    .section-padder {
        padding-top: 50px;
        padding-bottom: 30px; }
    .custom-tag {
        min-height: 220px !important; }
    .slick-prev, .slick-next {
        display: none !important; }
    form.centered {
        width: 70%; }
    .artwork-img {
        height: unset;
        width: 100%; } }


